import { Icon } from "@iconify/react";
import SearchIcon from "../assets/search.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../services/states/store";
import actions from "../services/states/actions";
import { transport } from "../services/Network";
import AddGroupSelectModal from "./modals/AddGroupSelectModal";
import toast from "react-hot-toast";
import DeleteModal from "./modals/DeleteModal";
import SelectGroup from "./selects/SelectGroup";
const TableTopBar = (props: {
  type: number;
  currentPage: number;
  pageName: string;
  action?: string;
  showAction?: boolean;
  showSelectGroup?: boolean;
  totalCount: number;
  showViewAll?: boolean;
  total?: number;
  checkedItems?: any[];
  updateSearchValue?: (arg0: React.SetStateAction<string>) => void;
  setCurrentPage: (arg0: React.SetStateAction<number>) => void;
  setAction: (newVal: React.SetStateAction<string>) => void;
}) => {
  const [showGroupsSelect, setShowGroupsSelect] = useState(false);
  const [showDelete, setShowDelete] = useState("");
  const selectedPersona = useSelector(
    (state: RootState) => state.selectedPersona
  );
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  const availablePersonas: any[] = useSelector(
    (state: RootState) => state.personas.personas
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availableGroups.length === 0 && props.showSelectGroup) {
      dispatch(actions.getGroups());
    }
    if (availablePersonas.length === 0 && props.pageName === "Assessment") {
      dispatch(actions.getPersonas());
    }
    if (selectedPersona?.trim()?.length > 0) {
      dispatch(actions.getAssessments(selectedPersona));
    }
  }, []);

  return (
    <div className="py-2 flex gap-4 max-lg:flex-col items-stretch lg:items-center justify-between">
      <div className="flex max-sm:items-center max-sm:flex-col gap-2 lg:gap-4 max-lg:justify-between max-lg:w-full">
        {props.showAction !== false && (
          <div className="flex gap-2 w-full">
            <div className="relative">
              <select
                name={"action" + props.type}
                id={"action" + props.type}
                value={props.action}
                onChange={(ev) => {
                  props.setAction(ev.target.value);
                }}
                className="border-2 text-ellipsis flex-1 w-48 lg:max-w-32 appearance-none border-gray-400 rounded-md px-2.5 pr-8 bg-white py-1.5 text-gray-600 focus:ring-0 focus:outline-none"
              >
                <option value="">Action</option>
                <option value="delete">Delete</option>
                {window.location.pathname.includes("/users") ? (
                  <option value="add_to_group">Add to group</option>
                ) : null}
              </select>
              <Icon
                className="absolute text-gray-500 right-3 pointer-events-none text-lg top-1/2 -translate-y-1/2"
                icon="octicon:chevron-down-12"
              />
            </div>

            <button
              disabled={
                !props.checkedItems ||
                props.checkedItems?.length === 0 ||
                props.action?.length === 0
              }
              onClick={async () => {
                if (
                  props.action === "add_to_group" &&
                  props.pageName === "user"
                ) {
                  setShowGroupsSelect(true);
                } else if (props.action === "delete") {
                  setShowDelete(props.checkedItems?.join(",") + "");
                }
              }}
              className="text-white rounded-md max-md:flex-1 disabled:opacity-50 bg-primary px-4 py-2"
            >
              Apply
            </button>
          </div>
        )}
        {props.updateSearchValue && (
          <div className="relative max-sm:w-full">
            <input
              type="text"
              placeholder={
                "Search" +
                (props.pageName.length > 0 ? " " + props.pageName : "")
              }
              onChange={(ev) => {
                if (props.updateSearchValue) {
                  props?.updateSearchValue(ev.target.value);
                  props?.setCurrentPage(0);
                }
              }}
              className="border-2 focus:ring-0 focus:outline-none w-full sm:w-52 lg:w-60 border-gray-400 pl-4 pr-10 py-1.5 placeholder:text-gray-400 text-black block rounded-md"
            />
            <img
              src={SearchIcon}
              className="absolute right-4 opacity-50 top-1/2 -translate-y-1/2"
              alt="search icon"
            />
          </div>
        )}
        <SelectGroup show={props.showSelectGroup} />
        {props.pageName == "Assessment" && props.type === 1 && (
          <div className="relative max-md:w-full">
            <select
              name="personas"
              id="personas"
              onChange={(ev) => {
                dispatch(actions.selectPersona(ev.target.value));
                dispatch(actions.getAssessments(ev.target.value));
              }}
              value={selectedPersona}
              className="bg-white h-10 w-full md:w-60 pr-8 appearance-none border-2 border-gray-400 text-gray-400 rounded-md px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            >
              <option defaultChecked value="">
                Select Persona
              </option>
              {availablePersonas.length > 0 &&
                availablePersonas.map((persona: any, index: number) => (
                  <option
                    key={"Select-option-group-" + index}
                    value={persona.persona_id}
                  >
                    {persona.persona_id}
                  </option>
                ))}
            </select>
            <Icon
              className="absolute right-2 text-gray-700 top-1/2 text-xl pointer-events-none -translate-y-1/2 z-50"
              icon="tabler:caret-down-filled"
            />
          </div>
        )}
      </div>
      <div className="flex items-center gap-4 max-lg:justify-end">
        {/* {props.totalCount > 1 && (
          <button className="border-2 border-gray-400 rounded-md px-2.5 pr-5 bg-white py-1.5 text-gray-600">
            View all
          </button>
        )}
      */}
        {props.total ? (
          <span className="text-gray-600">{props.total} items</span>
        ) : (
          <></>
        )}
        {props.totalCount > 0 ? (
          <div className="flex items-center gap-2">
            <button
              disabled={props.currentPage < 1}
              onClick={() => props.setCurrentPage((prev) => prev - 1)}
            >
              <Icon
                icon="octicon:chevron-left-12"
                className={`${props.currentPage < 1 ? "text-gray-500" : ""
                  } text-3xl`}
              />
            </button>
            <div className="flex items-center">
              {Array.from(
                Array(props.totalCount > 5 ? 5 : props.totalCount).keys()
              ).map((page: number) => {
                const pageNum =
                  (page % 5) + Math.floor(props.currentPage / 5) * 5;
                return (
                  <button
                    onClick={() => props.setCurrentPage(pageNum)}
                    key={"pagenumber" + props.type + "_" + pageNum}
                    className={`p-1 w-8 h-8 text-lg leading-4 ${props.currentPage === pageNum
                      ? "text-white bg-[#E6841F]"
                      : "text-gray-600"
                      } font-semibold rounded-full`}
                  >
                    {pageNum + 1}
                  </button>
                );
              })}
            </div>
            <button
              disabled={props.currentPage >= props.totalCount - 1}
              onClick={() => props.setCurrentPage((prev) => prev + 1)}
            >
              <Icon
                icon="octicon:chevron-right-12"
                className={`${props.currentPage >= props.totalCount - 1
                  ? "text-gray-500"
                  : ""
                  } text-3xl`}
              />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <AddGroupSelectModal
        show={showGroupsSelect}
        onSubmit={async (groupId: number) => {
          const res = await transport(
            "/trainee/add_to_group?group_id=" + groupId,
            {
              method: "PUT",
              data: {
                ids: props.checkedItems?.join(","),
              },
            }
          );
          if (res?.status === 200) {
            dispatch(actions.getGroups());
            dispatch(actions.getUsers());
            toast.success(res.data.message);
            setTimeout(() => {
              setShowGroupsSelect(false);
            }, 300);
          } else {
            toast.error(
              res?.data?.message ?? "Adding trainees to the group failed"
            );
          }
        }}
        close={() => setShowGroupsSelect(false)}
      />
      <DeleteModal
        id={showDelete}
        type={"Selected " + props.pageName + "s"}
        onDelete={async () => {
          if (props.pageName === "user") {
            const res = await transport("/trainee/multi", {
              method: "DELETE",
              data: {
                ids: props.checkedItems?.join(","),
              },
            });
            if (res?.status === 200) {
              dispatch(actions.getGroups());
              dispatch(actions.getUsers());
              toast.success(res.data.message);
              setShowDelete("");
            } else {
              toast.error(
                res?.data?.message ?? "Deleting trainees to the group failed"
              );
            }
          } else if (props.pageName === "group") {
            const res = await transport("/group/multi", {
              method: "DELETE",
              data: {
                ids: props.checkedItems?.join(","),
              },
            });
            if (res?.status === 200) {
              dispatch(actions.getGroups());
              dispatch(actions.getUsers());
              toast.success(res.data.message);
              setShowDelete("");
            } else {
              toast.error(
                res?.data?.message ?? "Deleting trainees to the group failed"
              );
            }
          }
        }}
        close={() => setShowDelete("")}
      />
    </div>
  );
};

export default TableTopBar;
