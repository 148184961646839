import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../services/states/store';
import actions from '../../services/states/actions';
import { Icon } from '@iconify/react';

const SelectGroup = ({ show }: { show: boolean | undefined }) => {
    const dispatch = useAppDispatch();
    const selectedGroup = useSelector((state: RootState) => state.selectedGroup);
    const availableGroups: any[] = useSelector(
        (state: RootState) => state.groups
    );
    const [groupOptions, setGroupOptions] = useState<any[]>([]);
    const userDetails = useSelector((state: RootState) => state.userDetails);
    useEffect(() => {
        if (userDetails && groupOptions?.length === 0) {
            if (localStorage.getItem("role") === "trainer") {
                setGroupOptions(availableGroups.filter((group: any) => group.status === "active"));
            } else {
                const groups = userDetails?.groups?.filter((group: any) => group.status === "active");
                setGroupOptions(groups);
            }
        }
    }, [availableGroups, userDetails]);

    return !show ? null : (
        <div className="relative max-md:w-full">
            <select
                name="groups"
                id="groups"
                value={selectedGroup}
                onChange={(ev) => {
                    dispatch(actions.selectGroup(ev.target.value));
                }}
                className="bg-white h-10 w-full md:w-60 pr-8 appearance-none border-2 border-gray-400 text-gray-400 rounded-md px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            >
                <option defaultChecked value="">
                    Select Group
                </option>
                {groupOptions?.length > 0 &&
                    groupOptions
                        .filter((grp) => grp.status === "active")
                        .sort((a, b) =>
                            a?.name.toString().localeCompare(b?.name.toString(), {
                                numeric: true,
                            })
                        )
                        .map((group: any, index: number) => (
                            <option
                                key={"Select-option-group-" + index}
                                value={group.id}
                            >
                                {group.name}
                            </option>
                        ))}
            </select>
            <Icon
                className="absolute right-2 text-gray-700 top-1/2 text-xl pointer-events-none -translate-y-1/2 z-50"
                icon="tabler:caret-down-filled"
            />
        </div>
    )
}

export default SelectGroup