import { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import FeedbackIcon from "../assets/discount.png";
import SearchIcon from "../assets/search.png";
import FeedbackCard from "../components/FeedbackCard";
import { transport } from "../services/Network";
import UserIcon from "../assets/usericon.png";
import toast from "react-hot-toast";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import MessageWidget from "../components/MessageWidget";
import actions from "../services/states/actions";
import { RootState, useAppDispatch } from "../services/states/store";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectGroup from "../components/selects/SelectGroup";
const TrainerFeedback = () => {
    const { traineeId } = useParams();
    const [feedbacks, setFeedbacks] = useState<any[]>([]);
    const [opened, setOpened] = useState(-1);
    const [filteredFeedbacks, setFilteredFeedbacks] = useState<any[]>([]);
    const [searchInput, setSearchInput] = useState("");
    const [conversations, setConversations] = useState<any[]>([]);
    const [conversationId, setConversationId] = useState<string>("");
    const userDetails = useSelector((state: RootState) => state.userDetails);
    const selectedGroup = useSelector(
        (state: RootState) => state.selectedGroup
    );
    const users: [] = useSelector((state: RootState) => state.users);
    const availableGroups: any[] = useSelector(
        (state: RootState) => state.groups
    );
    const chatBoxRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const [refreshed, setRefreshed] = useState(false);
    useEffect(() => {
        if (traineeId) {
            if (!users || users.length === 0) {
                dispatch(actions.getUsers());
            }
            if (availableGroups.length === 0) {
                dispatch(actions.getGroups());
            }
        }
    }, [])

    useEffect(() => {
        if (feedbacks.length === 0 || refreshed) {
            if (traineeId) {
                transport("trainer/feedback/" + traineeId).then((res) => {
                    if (res?.status === 200) {
                        setFeedbacks(res.data.assessments);
                    }
                });
                setRefreshed(false);
            } else {
                transport("trainee/feedback").then((res) => {
                    if (res?.status === 200) {
                        setFeedbacks(res.data.assessments);
                    }
                });
                setRefreshed(false);
            }
        }
    }, [refreshed]);
    useEffect(() => {
        let filteredFeedbackData = [];
        if (searchInput) {
            filteredFeedbackData = feedbacks.filter((val) =>
                val.persona_id.toLowerCase().includes(searchInput.toLowerCase())
            );
        } else {
            filteredFeedbackData = feedbacks;
        }
        if (selectedGroup && userDetails) {
            const user = users.find(
                (user: any) => user.id === traineeId
            );
            const personas: string[] = [].concat(
                ...(user ?? userDetails).groups
                    .filter((group: any) => {
                        return group?.personas && group.status === "active" && (group.id === parseInt(selectedGroup) || selectedGroup === "")
                    })
                    .map((group: any) => {
                        console.log(group)
                        return ((typeof group.personas === "string") ? group.personas.split(",") : group.personas)
                    })
            );
            filteredFeedbackData = filteredFeedbackData.filter((val) => personas.includes(val.persona_id));
        }
        setFilteredFeedbacks(filteredFeedbackData);
    }, [searchInput, feedbacks, selectedGroup, userDetails]);

    const downloadData = async () => {
        if (!chatBoxRef.current) {
            return;
        }
        try {
            const pdf = new jsPDF("p", "px", "a4", true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            let posY = 0;
            for (let i = 0; i < chatBoxRef?.current.children.length; i++) {
                const elem = chatBoxRef.current.children.item(i) as HTMLElement;
                if (elem) {
                    const canvas = await html2canvas(elem, {
                        scale: 1.5,
                    });
                    const h = canvas.height / 3;
                    if (posY + h >= pdfHeight) {
                        posY = 0;
                        pdf.addPage();
                    }
                    const imgData = canvas.toDataURL("image/png");
                    pdf.addImage(imgData, "PNG", 0, posY, pdfWidth, h);
                    posY += h;
                }
            }
            pdf.save("conversation_" + conversationId + ".pdf");
            setTimeout(() => {
                dispatch(actions.printingChange(0));
                setConversations([]);
            }, 300);
        } catch (error) {
            dispatch(actions.printingChange(0));
            toast.error("Download failed. Please try again later");
            setConversations([]);
        }
    };

    useEffect(() => {
        if (conversations.length > 0 && chatBoxRef.current) {
            setTimeout(async () => {
                downloadData();
            }, 300);
        }
    }, [conversations, chatBoxRef]);

    return (
        <Layout>
            <div className="bg-white rounded md:py-4 p-3 md:px-6">
                <div className="flex gap-2 md:gap-4 items-start">
                    <img
                        src={FeedbackIcon}
                        className="w-10 md:w-12 opacity-85"
                        alt="feedback"
                    />
                    <div className="flex-1 overflow-hidden">
                        <div className="flex items-center justify-between gap-4">
                            <div>
                                <h1 className="text-primary text-xl font-bold">
                                    Feedback: Training Sessions
                                </h1>
                                <div className="text-sm md:text-base opacity-85 font-light line-clamp-2 lg:max-w-[70%] my-2">
                                    Below are the results from your previous conversations. Click the
                                    drop down to reveal the assessments for each conversation
                                </div>
                            </div>
                            <SelectGroup show={true} />
                        </div>
                        <div className="relative md:min-w-96 md:w-1/3 mt-2">
                            <input
                                type="text"
                                value={searchInput}
                                onChange={(ev) => setSearchInput(ev.target.value)}
                                placeholder="Search Feedback (Example: Persona 1)"
                                className="border focus:ring-0 focus:outline-none w-full border-black pl-4 pr-10 py-1 placeholder:text-gray-400 text-black block max-md:text-sm rounded-md"
                            />
                            <img
                                src={SearchIcon}
                                className="absolute right-4 w-4 h-4 opacity-50 top-1/2 -translate-y-1/2"
                                alt="search icon"
                            />
                        </div>
                        {/* <div className="text-sm md:text-base py-2 md:py-4 flex items-center gap-1 md:gap-2 text-[#E37F3A]">
              <Icon
                onClick={() => {}}
                icon="material-symbols:close"
                className="md:text-xl cursor-pointer"
              />
              Your feedback from this conversation
            </div> */}
                        <div className="flex flex-col items-stretch w-full gap-4 py-4">
                            {filteredFeedbacks?.length > 0 ? (
                                filteredFeedbacks.map((feedback, index) => (
                                    <FeedbackCard
                                        onRefresh={() => setRefreshed(true)}
                                        showRefresh={!traineeId}
                                        opened={opened == feedback.id}
                                        setOpen={() =>
                                            setOpened(opened == feedback.id ? -1 : feedback.id)
                                        }
                                        key={"feedback_" + feedback.id}
                                        {...feedback}
                                        num={feedbacks.length - index}
                                        downloadData={() => {
                                            try {
                                                dispatch(actions.printingChange(2));
                                                transport("auth/swagger", {
                                                    method: "POST",
                                                    data: {
                                                        urlval:
                                                            process.env.REACT_APP_PERSON_MGMT_URL +
                                                            "/history/conversation/" +
                                                            feedback.conversation_id,
                                                        method: "GET",
                                                    },
                                                }).then((res) => {
                                                    if (res?.status === 200) {
                                                        const rawData = res?.data;
                                                        const messages = rawData
                                                            ?.slice(1, -1)
                                                            ?.split("role: ");
                                                        const msgs = messages
                                                            ?.map((message: string) => {
                                                                const roleMatch =
                                                                    message.match(/\b(user|model)\b/);
                                                                const textMatch =
                                                                    message.match(/text:\s*"([^"]*)"/s);
                                                                console.log(roleMatch);
                                                                const role = roleMatch
                                                                    ? roleMatch[0].trim()
                                                                    : "model";
                                                                const text = textMatch
                                                                    ? textMatch[1]
                                                                        .replace(/\\'/g, "'")
                                                                        .split("\\n")
                                                                        .join(" ")
                                                                        .trim()
                                                                    : "";

                                                                return { content: text, author: role };
                                                            })
                                                            .filter(
                                                                (val: any) => val.content.trim().length > 0
                                                            );

                                                        const conversation = msgs.map((msg: any) => {
                                                            const date = new Date();
                                                            return {
                                                                msg: msg.content
                                                                    ?.replace("content=", "")
                                                                    .trim(),
                                                                isSender: msg.author === "user",
                                                                time:
                                                                    (date.getHours() <= 9
                                                                        ? "0" + date.getHours()
                                                                        : date.getHours()) +
                                                                    ":" +
                                                                    (date.getMinutes() <= 9
                                                                        ? "0" + date.getMinutes()
                                                                        : date.getMinutes()),
                                                            };
                                                        });
                                                        setConversationId(feedback.conversation_id);
                                                        setConversations(conversation);
                                                    } else {
                                                        toast.error("Conversation is not downloadable");
                                                        dispatch(actions.printingChange(0));
                                                    }
                                                });
                                            } catch (error) {
                                                dispatch(actions.printingChange(0));
                                            }
                                        }}
                                    />
                                ))
                            ) : (
                                <div className="text-center text-gray-500 italic p-8 pt-16">
                                    No feedback found
                                </div>
                            )}
                        </div>
                        <div className="flex gap-4 items-center">
                            <p className="text-gray-600 max-md:text-xs">
                                Need additional help with completing this assignment? Get in
                                touch with your Trainer for assistance
                            </p>
                            <Link
                                to="/help"
                                target="_blank"
                                className="rounded max-md:text-xs py-1 md:py-2 px-3 md:px-6 bg-[#e6841f] text-white font-light"
                            >
                                Contact Trainer
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref={chatBoxRef}
                className="flex flex-col opacity-0 fixed top-0 left-0 pointer-events-none bg-white items-stretch pb-5 max-w-[95%] w-[64rem]"
            >
                {conversations.length > 0 &&
                    conversations.map(
                        (
                            convo: { isSender: boolean; msg: string; time: string },
                            index: number
                        ) => (
                            <MessageWidget
                                key={"Conversation_" + index}
                                isSender={convo.isSender}
                                userimg={
                                    convo.isSender && userDetails?.profile_pic
                                        ? userDetails.profile_pic
                                        : UserIcon
                                }
                                text={convo.msg}
                                time={convo.time}
                            />
                        )
                    )}
            </div>
        </Layout>
    );
};

export default TrainerFeedback;
