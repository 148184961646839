import { Icon } from "@iconify/react";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../services/states/actions";
import { RootState } from "../services/states/store";
import { useNavigate } from "react-router";
const TableRow = (props: {
  rowData: any[];
  rowindex: number;
  focused: boolean;
  type: string;
  setFocused: () => void;
  selected: boolean;
  updateChecked: (() => void) | undefined;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return props.rowData.length < 3 ||
    props.rowData[0] !== props.rowData[2] ||
    props.type === "leaderboard" ? (
    <tr onClick={() => {
      if (props.type === "leaderboard") {
        navigate("/feedback/" + props.rowData[0]);
      }
    }} onMouseOver={props.setFocused}>
      {props.rowData
        .slice(
          props.type === "user" ||
            props.type === "groups" ||
            props.type === "leaderboard"
            ? 1
            : 0
        )
        ?.map((col, index) => (
          <td
            className={`!p-0 cursor-pointer max-xl:text-sm ${props.focused
              ? "text-gray-800 bg-[#E7F6FD] font-bold xl:text-lg"
              : props.rowindex % 2 === 0
                ? "text-gray-600 bg-[#F1F1F1]"
                : "text-gray-600 bg-white"
              }`}
            key={"row_" + props.rowindex + "_" + index}
          >
            <div
              className={`flex items-start gap-2 py-3 pr-1 xl:py-6 ${index === 0 && props.focused
                ? "pl-2 md:pl-4 xl:pl-10 border-l-8 border-bgprimary"
                : "pl-4 md:pl-6 xl:pl-12"
                }`}
            >
              {index === 0 && props.updateChecked && (
                <div
                  onClick={() =>
                    props.updateChecked ? props.updateChecked() : () => { }
                  }
                  className={`w-4 h-4 p-0.5 mr-1 flex items-center justify-center md:mr-4 mt-1 rounded-md border-2 ${props.selected
                    ? "border-[#E6841F] text-[#E6841F]"
                    : "border-gray-500"
                    } `}
                >
                  {props.selected ? (
                    <Icon icon="streamline:check-solid" />
                  ) : null}
                </div>
              )}
              <div className="flex-1 w-full">
                <div
                  title={col.toString().replace(/, /g, "\n")}
                  className="whitespace-nowrap max-w-44 max-md:max-w-24 overflow-hidden text-ellipsis"
                >
                  {col}
                </div>

                {index === 0 &&
                  (props.type === "user" || props.type === "groups") &&
                  (props.focused ? (
                    <div className="w-full flex-1 pt-2 text-xs xl:text-sm text-gray-800 flex max-md:flex-wrap md:items-center gap-y-1 md:gap-1">
                      <button
                        onClick={() => {
                          if (props.type === "user") {
                            dispatch(
                              actions.viewTraineeToggle(props.rowData[0])
                            );
                          } else {
                            dispatch(actions.viewGroupToggle(props.rowData[0]));
                          }
                        }}
                        className="flex items-center gap-1 max-md:pl-1 pr-1"
                      >
                        <Icon icon="mdi:eye" className="text-[#E4851F]" />
                        <span>View</span>
                      </button>
                      <span className="w-1 max-w-0.5 min-w-0.5 bg-gray-600 max-md:hidden min-h-3.5" />
                      <button
                        onClick={() => {
                          if (props.type === "user") {
                            dispatch(
                              actions.editTraineeToggle(props.rowData[0])
                            );
                          } else {
                            dispatch(actions.editGroupToggle(props.rowData[0]));
                          }
                        }}
                        className="flex items-center gap-1 px-1"
                      >
                        <Icon icon="bxs:edit" className="text-[#E4851F]" />
                        <span>Edit</span>
                      </button>
                      <span className="w-1 max-w-0.5 min-w-0.5 bg-gray-600 max-md:hidden min-h-3.5" />
                      <button
                        onClick={() => {
                          if (props.type === "user") {
                            dispatch(
                              actions.deleteTraineeToggle(props.rowData[0])
                            );
                          } else {
                            dispatch(
                              actions.deleteGroupToggle(props.rowData[0])
                            );
                          }
                        }}
                        className="flex items-center gap-1 px-1"
                      >
                        <Icon
                          icon="fa-solid:trash-alt"
                          className="text-[#E4851F]"
                        />
                        <span>Delete</span>
                      </button>
                    </div>
                  ) : (
                    null
                  ))}
              </div>
            </div>
          </td>
        ))}
    </tr>
  ) : props.type === "user" ? (
    <tr onMouseOver={props.setFocused}>
      <td
        className={`!p-0 ${props.type !== "user" ? "" : "cursor-pointer"
          } max-xl:text-sm ${props.focused
            ? "text-gray-800 bg-[#E7F6FD] font-bold xl:text-lg"
            : props.rowindex % 2 === 0
              ? "text-gray-600 bg-[#F1F1F1]"
              : "text-gray-600 bg-white"
          }`}
        key={"row_" + props.rowindex + "_" + 1}
      >
        <div
          className={`flex items-start gap-2 py-3 pr-1 xl:py-6 ${props.focused
            ? "pl-10 md:pl-12 xl:pl-16 border-l-8 border-bgprimary"
            : "pl-12 md:pl-14 xl:pl-20"
            }`}
        >
          <div className="flex-1 w-full">
            <div
              className="whitespace-nowrap"
            >
              <div className="flex items-center gap-2">
                <p title={props.rowData[1].toString().replace(/, /g, "\n")}>

                  {props.rowData[1]}
                </p>
                <button title="Delete invitation" onClick={() => {
                  dispatch(
                    actions.deleteTraineeToggle(props.rowData[0], "invitation")
                  );
                }}>
                  <Icon
                    icon="fa-solid:trash-alt"
                    className="text-[#E4851F] text-base"
                  />
                </button>
              </div>
              <p className="pt-2 text-sm text-gray-600">{props.rowData[2]}</p>
            </div>
          </div>
        </div>
      </td>
      <td
        colSpan={props.rowData.length - 2}
        className={`!p-0 ${props.type !== "user" ? "" : "cursor-pointer"
          } max-xl:text-sm ${props.focused
            ? "text-gray-800 bg-[#E7F6FD] font-bold xl:text-lg"
            : props.rowindex % 2 === 0
              ? "text-gray-600 bg-[#F1F1F1]"
              : "text-gray-600 bg-white"
          }`}
        key={"row_" + props.rowindex + "_" + 2}
      >
        <div
          className={`flex items-center gap-2 py-3 pr-1 xl:py-6 pl-4 md:pl-6 xl:pl-12`}
        >
          <div className="flex-1 w-full">
            <div
              title="Invitation Pending"
              className="whitespace-nowrap text-center max-w-[50%] italic text-[#E6841F]"
            >
              Invitation Pending
            </div>
          </div>
        </div>
      </td>
    </tr>
  ) : null;
};

const Table = (props: {
  type: string;
  headers: string[];
  data: any[];
  checkedItems: any[];
  headerCodes: string[];
  updateChecked: ((arg0: React.SetStateAction<any[]>) => void) | undefined;
  disableSorting?: boolean;
}) => {
  const [focused, setFocused] = useState<any>(-1);
  const selectedOption: any[] = useSelector(
    (state: RootState) => state.sortTableData
  );
  const dispatch = useDispatch();

  const allSelected = useCallback((): boolean => {
    if (props.type !== "user" && props.type !== "groups") {
      return false;
    }
    for (const x of props.data) {
      if (!props.checkedItems.includes(x[0])) {
        return false;
      }
    }
    return true;
  }, [props.checkedItems, props.data, props.type]);

  return (
    <div className="max-w-full overflow-auto">
      <table
        className="w-full border overflow-hidden max-w-full border-gray-700 border-collapse"
        cellSpacing="0"
        cellPadding="0"
      >
        <thead className="max-w-full">
          <tr className="max-w-full">
            {props.headers?.map((header, index) => (
              <th
                className="border-b max-w-full border-gray-700 text-gray-600 bg-white py-1.5 xl:py-3 pl-4 md:pl-6 xl:pl-12 pr-1 font-normal"
                key={props.type + "_header_" + index}
              >
                <div className="flex items-center whitespace-nowrap flex-nowrap gap-2 ">
                  {index === 0 && props.updateChecked && (
                    <div
                      onClick={() => {
                        if (props.updateChecked) {
                          if (allSelected()) {
                            props.updateChecked([]);
                          } else {
                            props.updateChecked(
                              props.data.map((val) => val[0])
                            );
                          }
                        }
                      }}
                      className={`w-4 cursor-pointer h-4 p-0.5 mr-1 flex items-center justify-center md:mr-4 mt-1 rounded-md border-2 ${allSelected()
                        ? "border-[#E6841F] text-[#E6841F]"
                        : "border-gray-500"
                        } `}
                    >
                      {allSelected() ? (
                        <Icon icon="streamline:check-solid" />
                      ) : null}
                    </div>
                  )}
                  {header}
                  {!props.disableSorting && (
                    <Icon
                      onClick={() => {
                        if (
                          selectedOption.length === 3 &&
                          selectedOption[0] === props.type &&
                          selectedOption[1] === props.headerCodes[index] &&
                          selectedOption[2] === "desc"
                        ) {
                          dispatch(
                            actions.sortTableData([
                              props.type,
                              props.headerCodes[index],
                              "asc",
                            ])
                          );
                        } else {
                          dispatch(
                            actions.sortTableData([
                              props.type,
                              props.headerCodes[index],
                              "desc",
                            ])
                          );
                        }
                      }}
                      className={`text-gray-800 xl:text-xl 
                      selectedOption.length === 3 &&
                      selectedOption[0] === props.type &&
                      selectedOption[1] === props.headerCodes[index] &&
                      selectedOption[2] === "desc"
                        ? "rotate-180"
                        : ""
                    }`}
                      icon="icon-park-solid:down-one"
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-collapse">
          {props.data.map((row, index) => (
            <TableRow
              type={props.type}
              setFocused={() => setFocused(row[0])}
              focused={focused === row[0]}
              key={props.type + "_row_" + index}
              rowData={row}
              rowindex={index}
              selected={
                props.checkedItems.findIndex((val) => val === row[0]) > -1
              }
              updateChecked={
                props.updateChecked
                  ? () => {
                    if (props.updateChecked) {
                      props.updateChecked(
                        props.checkedItems.findIndex(
                          (val) => val === row[0]
                        ) > -1
                          ? props.checkedItems.filter((val) => val !== row[0])
                          : [...props.checkedItems, row[0]]
                      );
                    }
                  }
                  : undefined
              }
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
