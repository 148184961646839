import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import userIcon from "../assets/user.png";
import TableTopBar from "../components/TableTopBar";
import Table from "../components/Table";
import AddGroupModal from "../components/modals/AddGroupModal";

import { RootState, useAppDispatch } from "../services/states/store";
import { useSelector } from "react-redux";
import actions from "../services/states/actions";
import EditGroupModal from "../components/modals/EditGroupModal";
import DeleteModal from "../components/modals/DeleteModal";
import toast from "react-hot-toast";
import { transport } from "../services/Network";
import ViewGroupModal from "../components/modals/ViewGroupModal";

const TrainingGroupsListing = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [action, setAction] = useState("");
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [selected, setSelected] = useState<any[]>([]);
  const groups: [] = useSelector((state: RootState) => state.groups);
  const selectedOption: any[] = useSelector(
    (state: RootState) => state.sortTableData
  );
  const showEdit: string = useSelector(
    (state: RootState) => state.editGroupToggle
  );
  const showView: string = useSelector(
    (state: RootState) => state.viewGroupToggle
  );
  const showDelete: string = useSelector(
    (state: RootState) => state.deleteGroupToggle
  );
  const [filteredGroups, setFilteredGroups] = useState(groups as any[]);
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!groups || groups.length === 0) {
      dispatch(actions.getGroups());
    }
  }, []);
  useEffect(() => {
    if (searchInput?.length > 0) {
      setFilteredGroups([
        ...groups.filter((group: any) =>
          group.name.toLowerCase().includes(searchInput.toLowerCase())
        ),
      ]);
    } else {
      setFilteredGroups([
        ...groups.sort((a: any, b: any) =>
          selectedOption[2] === "desc"
            ? `${b[selectedOption[1] ? selectedOption[1] : "name"]
              }`.localeCompare(
                `${a[selectedOption[1] ? selectedOption[1] : "name"]}`
              )
            : `${a[selectedOption[1] ? selectedOption[1] : "name"]
              }`?.localeCompare(
                `${b[selectedOption[1] ? selectedOption[1] : "name"]}`
              )
        ),
      ]);
    }
  }, [searchInput, groups, selectedOption]);
  useEffect(() => {
    setFilteredGroups([
      ...filteredGroups.sort((a: any, b: any) =>
        selectedOption[2] === "desc"
          ? `${b[selectedOption[1] ? selectedOption[1] : "name"]
            }`.localeCompare(
              `${a[selectedOption[1] ? selectedOption[1] : "name"]}`
            )
          : `${a[selectedOption[1] ? selectedOption[1] : "name"]
            }`?.localeCompare(
              `${b[selectedOption[1] ? selectedOption[1] : "name"]}`
            )
      ),
    ]);
  }, [selectedOption]);

  return (
    <Layout>
      <div className="flex w-full pb-2 items-center gap-4">
        <img src={userIcon} alt="user" className="max-md:h-10" />
        <span className="text-3xl lg:text-4xl">
          Groups ({groups?.length ?? 0})
        </span>
        <button
          onClick={() => setShowAddGroup(true)}
          className="bg-[#E6841F] px-3 py-1 md:py-2 rounded-md text-white"
        >
          Add a group
        </button>
      </div>
      <TableTopBar
        action={action}
        setAction={setAction}
        pageName="group"
        updateSearchValue={setSearchInput}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        checkedItems={selected}
        total={filteredGroups.length}
        totalCount={Math.ceil(filteredGroups ? filteredGroups.length / 8 : 0)}
        type={1}
      />
      {filteredGroups?.length > 0 ? (
        <Table
          headers={["Names", "Personas", "Trainees", "Status"]}
          headerCodes={[
            "name",
            "personas",
            "trainees",
            "status",
          ]}
          data={filteredGroups
            ?.slice(currentPage * 8, currentPage * 8 + 8)
            .map((group) => [
              group["id"],
              group["name"],
              (group["personas"] as [])?.length ?? 0,
              (group["trainees"] as [])?.length ?? 0,
              group["status"] == "active" ? "Active" : "Completed",
            ])}
          checkedItems={selected}
          updateChecked={setSelected}
          type="groups"
        />
      ) : (
        <div className="text-center text-gray-500 italic p-8">
          No group found
          {searchInput.length > 0 ? " with given searched value" : ""}
        </div>
      )}
      <AddGroupModal show={showAddGroup} close={() => setShowAddGroup(false)} />
      <ViewGroupModal
        group={
          showView
            ? filteredGroups.find((val) => val.id == showView)
            : undefined
        }
        close={() => dispatch(actions.viewGroupToggle(""))}
      />
      <EditGroupModal
        id={showEdit}
        close={() => dispatch(actions.editGroupToggle(""))}
      />
      <DeleteModal
        id={showDelete}
        type="Group"
        close={() => dispatch(actions.deleteGroupToggle(""))}
        onDelete={async () => {
          const res = await transport("/group/" + showDelete, {
            method: "DELETE",
          });
          if (res?.status === 200) {
            dispatch(actions.getGroups());
            dispatch(actions.getUsers());
            toast.success(res.data.message);
            setTimeout(() => {
              dispatch(actions.deleteGroupToggle(""));
            }, 300);
          } else {
            toast.error(res.data.message);
          }
        }}
      />
    </Layout>
  );
};

export default TrainingGroupsListing;
