import { ActionType, RootState } from "./store";
export type LangOption = {
  value: string | undefined;
  label: string | undefined;
};
const initialstate: {
  [key: string]: any;
  languages: LangOption[];
} = {
  users: [],
  groups: [],
  selectedOption: "overview",
  userDetails: undefined,
  activeDetails: undefined,
  sidebarToggle: false,
  viewGroupToggle: "",
  viewTraineeToggle: "",
  editTraineeToggle: "",
  editGroupToggle: "",
  deleteTraineeToggle: {
    value: "",
    type: "",},
  deleteGroupToggle: "",
  sortTableData: ["", "", "asc"],
  personas: { personas: [], settings: [] },
  assesments: [],
  selectedPersona: localStorage.getItem("persona")
    ? localStorage.getItem("persona")
    : "",
  printingStatus: 0,
  selectedGroup: "",
  botSpeech: true,
  languages: [],
  languagesVoices: [],
  analyticsData: {},
  personaSettingsMap:{},
  leaderboardData: {},
};
const reducer = (state: RootState = initialstate, action: ActionType) => {
  switch (action.type) {
    case "GET_USER": {
      return {
        ...state,
        userDetails: action.payload?.data?.details ?? undefined,
        activeDetails:action.payload?.data?.active ?? undefined
      };
    }
    case "GET_USERS": {
      return {
        ...state,
        users: action.payload?.data?.trainees ?? [],
      };
    }
    case "GET_GROUPS": {
      return {
        ...state,
        groups: action.payload?.data?.groups ?? [],
      };
    }
    case "GET_PERSONAS": {
      return {
        ...state,
        personas: action.payload?.data ?? [],
      };
    }
    case "GET_LANGUAGES": {
      return {
        ...state,
        languages: action.payload?.data
          ? action.payload?.data.map((lang: any) => {
              return {
                value: lang["lang_code"],
                label: lang["lang_desc"],
              };
            })
          : [],
      };
    }
    case "GET_LANGUAGES_VOICES": {
      return {
        ...state,
        languagesVoices: action.payload?.data ? action.payload?.data : [],
      };
    }
    case "GET_ASSESSMENTS": {
      return {
        ...state,
        assesments: action.payload?.data ?? [],
      };
    }
    case "UPDATE_SELECTED": {
      return {
        ...state,
        selectedOption: action.payload,
      };
    }
    case "TOGGLE_SIDEBAR": {
      return {
        ...state,
        sidebarToggle: action.payload,
      };
    }

    case "TOGGLE_EDIT_TRAINEE": {
      return {
        ...state,
        editTraineeToggle: action.payload,
      };
    }
    case "TOGGLE_EDIT_GROUP": {
      return {
        ...state,
        editGroupToggle: action.payload,
      };
    }
    case "TOGGLE_VIEW_TRAINEE": {
      return {
        ...state,
        viewTraineeToggle: action.payload,
      };
    }
    case "TOGGLE_VIEW_GROUP": {
      return {
        ...state,
        viewGroupToggle: action.payload,
      };
    }
    case "TOGGLE_DELETE_TRAINEE": {
      return {
        ...state,
        deleteTraineeToggle: action.payload,
      };
    }
    case "TOGGLE_DELETE_GROUP": {
      return {
        ...state,
        deleteGroupToggle: action.payload,
      };
    }
    case "SORT_TABLE_DATA": {
      return {
        ...state,
        sortTableData: action.payload,
      };
    }
    case "SELECT_PERSONA": {
      return {
        ...state,
        selectedPersona: action.payload,
      };
    }
    case "CHANGE_PRINTING": {
      return {
        ...state,
        printingStatus: action.payload,
      };
    }
    case "SELECT_GROUP": {
      return {
        ...state,
        selectedGroup: action.payload,
      };
    }
    case "TOGGLE_BOT_SPEECH": {
      return {
        ...state,
        botSpeech: action.payload,
      };
    }
    case "GET_ANALYTICS_DATA": {
      return {
        ...state,
        analyticsData: action.payload?.data ? action.payload?.data : {},
      };
    }
    case "UPDATE_PERSONAS_SETTINS_MAP":{
      return{
        ...state,
        personaSettingsMap: action.payload
      }
    }
    case "UPDATE_LEADERBOARD_DATA": {
      return {
        ...state,
        leaderboardData: action.payload?.data ? {
          ...state.leaderboardData,
          [action.payload?.group_id]:
            action.payload?.data} : state.leaderboardData,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default reducer;
