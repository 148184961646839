//import { Icon } from "@iconify/react";
import closeImg from "../../assets/close.png";
import userIcon from "../../assets/usericon.png";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../services/states/store";
import actions from "../../services/states/actions";
import toast from "react-hot-toast";
import { transport } from "../../services/Network";
import SelectComp from "../selects/SelectComp";
const AddGroupModal = (props: { show: boolean; close: () => void }) => {
  const [selectedTrainee, setSelectedTrainee] = useState<any[]>([]);
  const [selectedPersonas, setSelectedPersonas] = useState<any[]>([]);
  const groupNameRef = useRef<HTMLInputElement>(null);
  const availableTrainees: any[] = useSelector(
    (state: RootState) => state.users
  );
  const availablePersonas: any[] = useSelector(
    (state: RootState) => state.personas.personas
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availableTrainees.length === 0) {
      dispatch(actions.getUsers());
    }
    if (availablePersonas.length === 0) {
      dispatch(actions.getPersonas());
    }
  }, []);
  const onClose = () => {
    setSelectedTrainee([]);
    setSelectedPersonas([]);
    if (groupNameRef.current) {
      groupNameRef.current.value = "";
    }
    props.close();
  };
  return props.show ? (
    <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center">
      <div className="p-0 w-[32rem] bg-white border border-black rounded">
        <div className="flex bg-bgprimary py-2.5 pl-8 pr-5 border-b-[5px] border-primary text-white items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <img src={userIcon} alt="usericon" />
            <h3 className="text-2xl">Add New Group</h3>
          </div>
          <img
            src={closeImg}
            className="cursor-pointer"
            onClick={props.close}
            alt="close"
          />
        </div>
        <div className="w-full flex flex-col gap-4 items-stretch bg-white px-8 py-6">
          <div>
            <label className="block pb-1 pl-0.5 text-gray-600" htmlFor="title">
              Group Title
            </label>
            <input
              type="text"
              placeholder="Title"
              name="title"
              id="title"
              ref={groupNameRef}
              className="bg-bgsecondary w-full border border-gray-400 placeholder:text-gray-400 text-gray-600 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            />
          </div>
          <SelectComp
            label="Add to Group"
            options={availableTrainees.map((val) => {
              return {
                label: val.fullname,
                value: val.id,
              };
            })}
            selectedValues={selectedTrainee}
            placeholder="Select Trainee to add"
            setSelectedValues={setSelectedTrainee}
            order={false}
          />
          <SelectComp
            label="Assign Personas"
            options={availablePersonas.map((val) => {
              return {
                label: val.persona_id,
                value: val.persona_id,
              };
            })}
            selectedValues={selectedPersonas}
            placeholder="Select persona to add"
            setSelectedValues={setSelectedPersonas}
            order={false}
          />
          <div className="h-5"></div>
          <div className="flex justify-between items-center">
            <button
              onClick={async () => {
                if (groupNameRef.current?.value) {
                  if (groupNameRef.current.value.trim().length <= 1) {
                    toast.error("Group title must be greater than 1 character");
                    return;
                  }
                  console.log(groupNameRef.current?.value);
                  console.log(selectedTrainee);
                  // "/group/"
                  const res = await transport("/group/", {
                    method: "POST",
                    data: {
                      name: groupNameRef.current?.value,
                      trainees: selectedTrainee.reduce((acc, current) => {
                        acc.push(current.value);
                        return acc;
                      }, []),
                      assignments: undefined,
                      personas: selectedPersonas.reduce((acc, current) => {
                        acc.push(current.value);
                        return acc;
                      }, []),
                    },
                  });
                  if (res?.status === 201) {
                    dispatch(actions.getGroups());
                    toast.success("Group created successfully");
                    setTimeout(() => {
                      onClose();
                    }, 300);
                  } else {
                    toast.error(res.data.message);
                  }
                } else {
                  toast.error("Group title is required");
                }
              }}
              className="text-white flex-1 max-w-40 bg-primary rounded py-2.5 px-5 text-base"
            >
              Save Group
            </button>
            <span className="text-xl text-gray-500">or</span>
            <button
              onClick={onClose}
              className="bg-gray-200 flex-1 max-w-40 text-gray-600 rounded py-2.5 px-5 text-base"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AddGroupModal;
