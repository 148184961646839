import { Icon } from "@iconify/react";
import closeImg from "../../assets/close.png";
import userIcon from "../../assets/usericon.png";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../services/states/store";
import actions from "../../services/states/actions";
import { transport } from "../../services/Network";
import toast from "react-hot-toast";
import SelectComp from "../selects/SelectComp";

// const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const EditTraineeModal = (props: { id: string; close: () => void }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<any[]>([]);
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  const availableUsers: any[] = useSelector((state: RootState) => state.users);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availableGroups.length === 0) {
      dispatch(actions.getGroups());
    }
    if (availableUsers.length === 0) {
      dispatch(actions.getUsers());
    }
  }, []);
  const onClose = () => {
    setSelectedGroup([]);
    setName("");
    setEmail("");
    props.close();
  };
  useEffect(() => {
    if (availableUsers.length > 0) {
      const user = availableUsers.find((val) => val.id === props.id);
      if (user) {
        setEmail(user.email);
        setSelectedGroup(
          user.groups.map((val: any) => {
            return {
              label: val.name,
              value: val.id,
            };
          })
        );
        setName(user.fullname);
      }
    }
  }, [availableUsers, props.id]);

  return props.id?.length > 0 ? (
    <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center">
      <div className="p-0 w-[32rem] bg-white border border-black rounded">
        <div className="flex bg-bgprimary py-2.5 pl-8 pr-5 border-b-[5px] border-primary text-white items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <img src={userIcon} alt="usericon" />
            <h3 className="text-2xl">Edit Trainee</h3>
          </div>
          <img
            src={closeImg}
            className="cursor-pointer"
            onClick={props.close}
            alt="close"
          />
        </div>
        <div className="w-full flex flex-col gap-4 items-stretch bg-white px-8 pt-6 pb-5">
          <div>
            <label className="block pb-1 pl-0.5 text-gray-600" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(ev) => {
                setName(ev.target.value);
              }}
              name="name"
              required
              id="name"
              className="bg-bgsecondary w-full border border-gray-400 placeholder:text-gray-400 text-gray-600 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            />
          </div>
          <div>
            <label className="block pb-1 pl-0.5 text-gray-600" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              placeholder="Enter Email Address"
              name="email"
              value={email}
              disabled
              title="Email cannot be edited"
              id="email"
              className="bg-bgsecondary disabled:opacity-50 w-full border border-gray-400 placeholder:text-gray-400 text-gray-600 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            />
          </div>
          <SelectComp
            label="Add to Group"
            options={availableGroups.map((val) => {
              return {
                label: val.name,
                value: val.id,
              };
            })}
            selectedValues={selectedGroup}
            placeholder="Select group to add"
            setSelectedValues={setSelectedGroup}
            order={true}
          />
          <div className="h-5"></div>
          <div className="flex justify-between items-center">
            <button
              onClick={async () => {
                if (name) {
                  if (name.trim().length <= 1) {
                    toast.error("Name must be greater than 1 character");
                    return;
                  }
                  if (name.trim().length === 0) {
                    toast.error("Email must not be blank");
                    return;
                  }

                  const res = await transport("/trainee/", {
                    method: "PUT",
                    data: {
                      fullname: name.trim(),
                      id: props.id,
                      groups: selectedGroup.reduce((acc, curr) => {
                        acc.push(curr.value);
                        return acc;
                      }, []),
                    },
                  });
                  if (res?.status === 200) {
                    dispatch(actions.getUsers());
                    dispatch(actions.getGroups());
                    toast.success(res.data.message);
                    setTimeout(() => {
                      onClose();
                    }, 300);
                  } else {
                    toast.error(res.data.message);
                  }
                } else {
                  toast.error("Name of trainee is required");
                }
              }}
              className="text-white flex-1 max-w-40 bg-primary rounded py-2.5 px-5 text-base"
            >
              Save Trainee
            </button>
            <span className="text-xl text-gray-500">or</span>
            <button
              onClick={onClose}
              className="bg-gray-200 flex-1 max-w-40 text-gray-600 rounded py-2.5 px-5 text-base"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default EditTraineeModal;
