import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../services/states/store";
import { useSelector } from "react-redux";
import closeImg from "../../assets/close.png";
import actions from "../../services/states/actions";

const DeleteModal = (props: {
  id: string;
  type: string;
  close: () => void;
  onDelete: () => void;
}) => {
  const [name, setName] = useState("");
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  const availableTrainees: any[] = useSelector(
    (state: RootState) => state.users
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availableGroups.length === 0 && props.type === "Group") {
      dispatch(actions.getGroups());
    }
    if (availableTrainees.length === 0 && props.type === "Trainee") {
      dispatch(actions.getUsers());
    }
  }, []);
  const onClose = () => {
    setName("");
    props.close();
  };
  useEffect(() => {
    if (availableGroups.length > 0 && props.type === "Group") {
      const group = availableGroups.find((val) => val.id === props.id);
      if (group) {
        setName(group.name);
      }
    }

    if (availableGroups.length > 0 && props.type === "Trainee") {
      let trainee = availableTrainees.find((val) => val.id === props.id);
      if (!trainee) {
        trainee = availableTrainees.find((val) => val.email === props.id);
      }
      if (trainee) {
        setName(trainee.fullname);
      }
    }
  }, [availableGroups, availableTrainees, props.id, props.type]);
  return props.id ? (
    <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center">
      <div className="p-0 w-[32rem] bg-white border border-black rounded">
        <div className="flex bg-bgprimary py-2.5 pl-8 pr-5 border-b-[5px] border-primary text-white items-center justify-between gap-4">
          <h3 className="text-2xl">Delete {props.type}</h3>
          <img
            src={closeImg}
            className="cursor-pointer"
            onClick={props.close}
            alt="close"
          />
        </div>
        <div className="w-full flex flex-col gap-4 items-stretch bg-white px-8 py-6">
          <p className="text-xl pb-6">
            Are you sure you want to delete <b>{name ? name : props.type}</b>?
          </p>
          <div className="flex justify-between items-center">
            <button
              onClick={props.onDelete}
              className="text-white flex-1 max-w-40 bg-[#E6841F] font-semibold rounded py-2.5 px-5 text-base"
            >
              Delete
            </button>
            <span className="text-xl text-gray-500">or</span>
            <button
              onClick={onClose}
              className="bg-gray-200 flex-1 max-w-40 text-gray-600 rounded py-2.5 px-5 text-base"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default DeleteModal;
